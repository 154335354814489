.loader {
  height: 5rem;
  width: 5rem;

  position: fixed;
  top: 50%;
  left: 50%;

  animation: spin 2s infinite linear;
}

@keyframes spin {
  from {
    /*transform: rotate(0deg);*/
    transform: translate(-50%, -50%) rotate(0deg);
  }
  to {
    /*transform: rotate(360deg);*/
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
